import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store';
import { filterMovies } from '../../utils/filter-movies'
import { IMovie } from 'interfaces';


export const initialState: IMovie[] = [];

export const moviesSlice = createSlice({
    name: 'movies-immutable',
    initialState,
    reducers: {
        setMovies: (state, action: PayloadAction<IMovie[]>) => {
            return [...action.payload]
        }
    }
})

// selectors 

export const selectFilteredMovies = (state: RootState) => {
    const { tagAssociations, movies, tagFilter } = state
    return filterMovies(tagFilter, tagAssociations, movies)
}


export const { setMovies } = moviesSlice.actions
export default moviesSlice.reducer