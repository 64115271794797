import { ITagData } from "src/interfaces";

export const addTagToData = (key: string | number, tagValue: string, data: ITagData, limit: number = 5): ITagData => {
    const _key = String(key)
    if (data[_key] && data[_key].length > limit - 1) {
        return data
    }
    const newValue: string[] = data[_key] ? [...data[_key], tagValue] : [tagValue]
    return { ...data, [_key]: newValue }
}


export const removeTagFromData = (key: string | number, tagValue: string, data: ITagData) => {
    const _key = String(key)
    const newValue: string[] = data[key] ? data[key].filter(d => d !== tagValue) : []
    return { ...data, [_key]: newValue }
}