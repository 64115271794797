import './App.scss';
import { Flock } from 'features/flock'

function App() {
  return (
    <div className="App">
      <section>
        <Flock />
      </section>  
    </div>
  );
}

export default App;
