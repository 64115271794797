import { ITagData } from "src/interfaces";
export const addTagsToStorage = (tags: string[]) => {
    window.localStorage.setItem('tags', JSON.stringify(tags))
}
export const addTagsAssociationsToStorage = (tags: ITagData) => {
    window.localStorage.setItem('tagsAssociations', JSON.stringify(tags))
}

export const getTagsFromStorage = (): string[] | null => window.localStorage.getItem('tags')
    ? JSON.parse(window.localStorage.getItem('tags') as string) as string[]
    : null

export const getTagsAssociationsFromStorage = (): ITagData | null => window.localStorage.getItem('tagsAssociations')
    ? JSON.parse(window.localStorage.getItem('tagsAssociations') as string) as ITagData
    : null