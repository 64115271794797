import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import tagsReducer from 'features/tags/tagsSlice'
import tagAssociationsReducer from 'features/tag-associations/tag-associations'
import tagFilterReducer from 'features/tag-filters/tagFiltersSlice'
import moviesReducer from 'features/movies/moviesSlice'
import { moviesAPI } from 'services/moviesService'

export const store = configureStore({
  reducer: {
    tags: tagsReducer,
    tagFilter: tagFilterReducer,
    tagAssociations: tagAssociationsReducer,
    movies: moviesReducer,
    [moviesAPI.reducerPath]: moviesAPI.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(moviesAPI.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

setupListeners(store.dispatch);