import { createSlice, PayloadAction, current } from '@reduxjs/toolkit'
import { ITagData, ITagPayload } from 'interfaces/ITagData'
import { addTagToData, removeTagFromData } from '../../utils/tag-logic'
import { addTagsAssociationsToStorage, getTagsAssociationsFromStorage } from 'services/tagService'

export const initialState: ITagData = getTagsAssociationsFromStorage() || {};

export const tagAssociationSlice = createSlice({
    name: 'tag-associations',
    initialState,
    reducers: {
        addTagAssociation: (state, action: PayloadAction<ITagPayload>) => {
            const { key, value } = action.payload
            const currentValue = current(state)[key]
            if (currentValue && currentValue.indexOf(value) !== -1) {
                return state
            }
            if (!currentValue) {
                state[key] = [value]
            } else {
                state = addTagToData(key, value, state)
            }
            addTagsAssociationsToStorage(state)
            return state
        },

        removeTagAssociation: (state, action: PayloadAction<ITagPayload>) => {
            const { key, value } = action.payload
            state = removeTagFromData(key, value, state)
            addTagsAssociationsToStorage(state)
            return state
        }
    }
})

export const { addTagAssociation, removeTagAssociation } = tagAssociationSlice.actions
export default tagAssociationSlice.reducer