import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const BASE_URL = 'https://my.api.mockaroo.com/movies.json';
const KEY = 'key=bf3c1c60'

export const moviesAPI = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    reducerPath: "moviesAPI",
    endpoints: (builder) => ({
        getMoviesWithKey: builder.query({
            query: (key: string | null = KEY) => `/$?{key}`
        }),
        getAllMovies: builder.query({ query: (key) => `/?${KEY}` })
    }),
})

// Export hooks for usage in functional components
export const { useGetMoviesWithKeyQuery, useGetAllMoviesQuery } = moviesAPI
