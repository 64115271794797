
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addTagsToStorage, getTagsFromStorage } from 'services/tagService'

const tags = getTagsFromStorage()
export const initialState: string[] = tags ? tags : ['Romcom', 'Horror']

export const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        addTag: (state, action: PayloadAction<string>) => {
            if (state.map(d => d.toLocaleLowerCase()).filter((dd: string) => dd === action.payload.toLocaleLowerCase()).length > 0) {
                return state
            }
            const newState = [...state, action.payload]
            addTagsToStorage(newState)
            return newState
        }
    }
})

export const { addTag } = tagsSlice.actions
export default tagsSlice.reducer