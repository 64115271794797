
import { useEffect, useRef } from 'react'
import { flockingSketch } from 'p5-typescript-boilerplate'
import p5 from 'p5'

import './flock.scss'
import githubLogo from '../../images/logo-github.svg'
import instaLogo from '../../images/logo-instagram.svg'
import mailLogo from '../../images/mail-open-outline.svg'
import linkedInLogo from '../../images/logo-linkedin.svg'


export const Flock = () => {
    const divRef = useRef<HTMLDivElement>(null);
    let flockRef: p5 | null = null

    useEffect(() => {
  
        console.log(flockRef)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        if (!flockRef && divRef.current) {
            console.log(divRef.current?.childNodes.length)
            if (divRef.current?.childNodes.length < 1) {
                flockRef = new p5(flockingSketch({ width: 900, height: 600 }), divRef.current as HTMLDivElement)
            }
        }

    })

    const imageStyle = {  width: 30, padding: '1rem'}

    return <div className='search-box-container'>
        <div className="biog">
            <p><span style={{fontWeight: 600}}>Graham Clapham — </span><span style={{fontWeight: 400}}>Developer</span></p>
            <span style={{fontWeight: 300}}>
            <p>I’m Graham.</p>
            <p>I live in London and I make things with code.</p>
            <p>I’ve worked for Lloyds Banking Group, Deutsche Bank, Thomson Reuters, IHS Markit and OpenFin amongst others.</p>
            <p>I love JavaScript, TypeScript, React, Svelte and anything which puts pixels on screens.</p>
            <p>I'm working on my new site, but, in the meantime have a play with my version of Reynolds’ flocking algorithm (move your mouse and they will follow, press it they will run away), checkout my GitHub, InstaGram, LinkedIn or just say hello.</p>
            </span>
            <a href='https://www.instagram.com/grahamclapham/' target='_blank'>
               <img alt='Instagram' style={imageStyle} src={instaLogo} />
            </a>
            <a href='https://github.com/mrClapham' target='_blank'>
                <img alt="Github" style={imageStyle} src={githubLogo} />
            </a>
            <a href='https://www.linkedin.com/in/grahamclapham' target='_blank'>
                <img alt="Mail" style={imageStyle} src={linkedInLogo} />
            </a>
            <a href='mailto:clapham.graham@gmail.com' target='_blank'>
                <img alt="Mail" style={imageStyle} src={mailLogo} />
            </a>
        </div>
        <div id='flockHolder' ref={divRef} >
        </div>

    </div>
}