import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const initialState: string[] = [];

export const tagFilters = createSlice({
    name: 'tag-filters',
    initialState,
    reducers: {
        setTagFilters: (state, action: PayloadAction<string>) => {
            if (action.payload.length === 0) {
                return []
            } else {
                return [action.payload]
            }
        },
        addTagFilter: (state, action: PayloadAction<string>) => {
            state.push(action.payload)
            return state
        },

        removeTagFilter: (state, action: PayloadAction<string>) => {
            return state.filter(d => d !== action.payload)
        }
    }
})

export const { addTagFilter, removeTagFilter, setTagFilters } = tagFilters.actions
export default tagFilters.reducer